import {
  downloadByGet, get, post, Delete,
} from '@/libs/axios'

export const LdapConfigCheck = params => post('/sys/ldap/config/check', params)
export const LdapConfigInfo = () => get('/sys/ldap/config/info')
export const LdapUserSync = () => get('/sys/ldap/user/sync')
export const ClearUser = () => Delete('/sys/ldap/user/clear')
export const SaveLdapConfig = params => post('/sys/ldap/config/save', params)
export const LdapUserAdd = params => post('/sys/ldap/user/add', params)
export const LdapUserDel = params => post('/sys/ldap/user/delete', params)
export const LdapUserPage = params => post('/sys/ldap/user/page', params)
export const ListDept = searchStr => {
  const url = searchStr ? `/system/dept/ListDept?searchStr=${searchStr}` : '/system/dept/ListDept'
  return get(url)
}

export const ImportDept = params => post('/system/dept/import', params)
export const ExportDept = () => downloadByGet(`/system/dept/export?_t=${Date.parse(new Date())}`)
export const DownloadDeptImportTemplate = () => downloadByGet(`/system/dept/import_template?_t=${Date.parse(new Date())}`)
export const DelDept = id => post('/system/dept/DelDept', id)
export const QueryUserListByDeptId = id => get(`/system/dept/QueryUserList?id=${id}`)
export const AddDeptUser = params => post('/system/dept/AddDeptUser', params)

export const saveOrUpdateDepts = params => {
  const submitUrl = params.id ? '/system/dept/UpdateDept' : '/system/dept/CreatDept'
  return post(submitUrl, params)
}

export const AssignDeptPrincipal = params => post('/system/dept/AssignDeptPrincipal', params)
export const DeleteCurrentDeptUser = params => post('/system/dept/DeleteCurrentDeptUser', params)
