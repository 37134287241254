<template>
  <el-tabs v-model="activeName">
    <el-tab-pane
      label="AD域配置"
      name="1"
    >
      <AdareaSet v-if="activeName === '1'" />
    </el-tab-pane>
    <el-tab-pane
      label="同步账号"
      name="2"
    >
      <SyncAccount v-if="activeName === '2'" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import AdareaSet from '@/views/system/auth/AdareaSet.vue'
import SyncAccount from '@/views/system/auth/SyncAccount.vue'

export default {
  components: {
    AdareaSet,
    SyncAccount,
  },
  data() {
    return {
      activeName: '1',
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss">

</style>
