<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter
          :search.sync="listQuery.SearchStr"
          @handleFilter="handleFilter"
        />
        <div class="flex-end">
          <el-button
            v-if="addBtn"
            type="primary"
            size="small"
            @click="addToSystem"
          >
            添加到系统
          </el-button>
          <el-button
            v-if="delPerm"
            type="danger"
            size="small"
            @click="clearUser"
          >
            一键清除
          </el-button>
          <el-button
            v-if="delPerm"
            type="danger"
            size="small"
            @click="beatchDelUser"
          >
            删除
          </el-button>
        </div>

      </div>
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        :max-height="pageH - 140"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="dataListSelectionChangeHandle"
      >
        <el-table-column
          type="selection"
          width="50"
        />
        <el-table-column
          label="工号"
          :show-overflow-tooltip="true"
          prop="employeeNumber"
        />
        <el-table-column
          :show-overflow-tooltip="true"
          label="姓名"
          prop="name"
        />
        <el-table-column
          prop="distinguishedName"
          label="登录名"
        />
        <el-table-column
          prop="department"
          label="部门"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="mobile"
          label="手机号"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="updateTime"
          width="150"
          label="更新时间"
        >
          <template slot-scope="{row}">
            {{ formatDateStr(row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          width="80"
          label="状态"
        >
          <template slot="header">
            <TableHeaderFilterDropdown
              :key-str="'status'"
              :select-list.sync="selectList"
              :label="'状态'"
              :value.sync="listQuery.status"
              :dropdown-options="statusOption"
              @handleFilter="handleFilter"
            />
          </template>
          <template slot-scope="{row}">
            <StatusBadge
              :label="statusObj[row.status]"
              :color="statusColorObj[row.status]"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="!row.status &&addBtn"
              :underline="false"
              class="mr10"
              type="primary"
              @click="addToSystemSign(row.id)"
            >
              <el-tooltip
                content="添加到系统"
                placement="top"
                effect="light"
              >
                <i class="el-icon-circle-plus-outline" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="delUserSing(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </b-card>
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import {
  error, getBtnPerms, success, verifyBtnPerm,
} from '@core/utils/utils'
import {
  LdapUserPage, LdapUserAdd, LdapUserDel, ClearUser,
} from '@/api/system/auth/auth'
import TableHeaderFilterDropdown from '@/@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import FilterSelectList from '@/@core/components/filter-select-list/FilterSelectList.vue'

export default {
  components: {
    FilterSelectList,
    TableHeaderFilterDropdown,
    pagination,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        status: '',
      },
      tableData: [],
      selectList: [],
      statusOption: [
        { label: '在系统', value: 1 },
        { label: '不在系统', value: 0 },
      ],
      statusObj: {
        1: '在系统',
        0: '不在系统',
      },
      statusColorObj: {
        1: '#3CB371',
        0: '#ff6633',
      },
      dataListSelections: [],
      delPerm: false,
      addBtn: false,
    }
  },
  created() {
    this.getList()
    getBtnPerms(btnPerms => {
      this.delPerm = verifyBtnPerm(btnPerms, 'auth-3')
      this.addBtn = verifyBtnPerm(btnPerms, 'auth-4')
    })
  },
  methods: {
    addToSystem() {
      if (this.dataListSelections.length === 0) {
        error('请选择数据')
      } else {
        const ids = []
        this.dataListSelections.forEach(item => {
          ids.push(item.id)
        })
        this.ldapUserAdd(ids)
      }
    },
    addToSystemSign(id) {
      const ids = []
      ids.push(id)
      this.ldapUserAdd(ids)
    },
    ldapUserAdd(ids) {
      LdapUserAdd({ ids }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.dialogVisible = false
          this.handleFilter()
        } else {
          error(resData.msg)
        }
      })
    },
    dataListSelectionChangeHandle(val) {
      this.dataListSelections = val
    },
    beatchDelUser() {
      if (this.dataListSelections.length === 0) {
        error('请选择数据')
      } else {
        const ids = []
        this.dataListSelections.forEach(item => {
          ids.push(item.id)
        })
        this.delLadpUser(ids)
      }
    },
    delUserSing(id) {
      const ids = []
      ids.push(id)
      this.delLadpUser(ids)
    },
    delLadpUser(ids) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        LdapUserDel({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    clearUser() {
      this.$confirm('确认清除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        ClearUser().then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      const newQuery = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        SearchStr: this.listQuery.SearchStr,
        status: this.listQuery.status === '' ? -1 : this.listQuery.status,
      }
      LdapUserPage(newQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
