<template>
  <b-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="140px"
    >
      <el-form-item
        label="服务器IP地址"
        prop="serverIp"
        :rules="[ { required: true, message: '服务器IP地址不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.serverIp"
          placeholder="服务器IP地址"
        />
      </el-form-item>
      <el-form-item
        label="端口"
        prop="serverPort"
        :rules="[ { required: true, message: '端口不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model.number="form.serverPort"
          placeholder="请输入端口"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="AD域管理员用户名"
        prop="adminAccount"
        :rules="[ { required: true, message: 'AD域管理员用户名能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.adminAccount"
          placeholder="请输入AD域管理员用户名"
        />
      </el-form-item>
      <el-form-item
        label="AD域管理员密码"
        prop="adminPassword"
        :rules="[ { required: true, message: 'AD域管理员密码能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.adminPassword"
          type="password"
          placeholder="请输入AD域管理员密码"
        />
      </el-form-item>
      <el-form-item
          label="搜索入口"
      >
        <el-input
            v-model="form.filterStr"
            placeholder="请输入搜索入口"
        />
      </el-form-item>
      <el-form-item
          label="超时配置"
      >
        <el-input
            v-model.number="form.timeout"
            type="number"
            placeholder="请输入超时配置"
        />
      </el-form-item>
      <div
        class="dialog-footer text-center"
      >
        <el-button @click="checkLadpConfig">
          检 测
        </el-button>
        <el-button
          v-if="savePerm"
          type="primary"
          :disabled="loading"
          @click="submitForm"
        >
          保 存
        </el-button>
        <el-button
          v-if="syncBtn && form.serverIp!== ''"
          type="primary"
          @click="syncAccount"
        >
          同步账号信息
        </el-button>
      </div>
    </el-form>
  </b-card>
</template>
<script>
import {
  error, getBtnPerms, success, verifyBtnPerm,
} from '@/@core/utils/utils'
import {
  LdapConfigCheck, LdapConfigInfo, SaveLdapConfig, LdapUserSync,
} from '@/api/system/auth/auth'

export default {
  data() {
    return {
      form: {
        adminPassword: '',
        adminAccount: '',
        serverIp: '',
        serverPort: '',
        filterStr: '',
        timeout: 0,
      },
      loading: false,
      showAddBtn: false,
      savePerm: false,
      syncBtn: false,
    }
  },
  created() {
    this.getLadpConfig()
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'auth-1')
      this.syncBtn = verifyBtnPerm(btnPerms, 'auth-2')
    })
  },
  methods: {
    ldapUserSync() {
      this.loading = true
      LdapUserSync().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
        } else {
          error(resData.msg)
        }
      })
    },
    getLadpConfig() {
      LdapConfigInfo().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.serverPort = this.form.serverPort ? this.form.serverPort : ''
        }
      })
    },
    checkLadpConfig() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          LdapConfigCheck(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.showAddBtn = true
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
            error('服务器错误')
          })
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          SaveLdapConfig(this.form).then(res2 => {
            this.loading = false
            const resData2 = res2.data
            if (resData2.code === 0) {
              success(resData2.msg)
            } else {
              error(resData2.msg)
            }
          })
          // LdapConfigCheck(this.form).then(res => {
          //   const resData = res.data
          //   if (resData.code === 0) {
          //     // success(resData.msg)
          //     this.loading = true
          //     SaveLdapConfig(this.form).then(res2 => {
          //       this.loading = false
          //       const resData2 = res2.data
          //       if (resData2.code === 0) {
          //         success(resData2.msg)
          //       } else {
          //         error(resData2.msg)
          //       }
          //     })
          //   } else {
          //     error(resData.msg)
          //   }
          // })
        }
      })
    },
    syncAccount() {
      this.confirm('确定进行同步账号信息吗？通过的账号信息会在【同步账号】列表里', () => {
        this.ldapUserSync()
      })
    },
  },
}
</script>
<style lang="scss">

</style>
